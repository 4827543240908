import type { ShowToast, OpenDialog } from './type-helpers';
import type { ExtensionDeclaration } from '.';
import type { FieldExtensionDeclaration } from './field';
import type { openAssetPicker as OpenAssetPicker } from './type-helpers/openAssetPicker';

// see https://github.com/krakenjs/zoid/blob/master/src/component/props.js#L53
export const reservedExtensionProps = [
  'timeout',
  'window',
  'close',
  'focus',
  'resize',
  'uid',
  'cspNonce',
  'getParent',
  'getParentDomain',
  'hide',
  'show',
  'export',
  'onDisplay',
  'onRendered',
  'onRender',
  'onClose',
  'onDestroy',
  'onResize',
  'onFocus',
  'onError',
  'onProps',
];

export const ExtensionPermission = {
  INPUT: 'INPUT',
  FORM: 'FORM',
  API: 'API',
} as const;

export const ExtensionType = {
  formSidebar: 'formSidebar',
  field: 'field',
} as const;

export const ConfigType = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
} as const;

export interface IExtensionDeclaration {
  name: string;
  description?: string;
  permissions?: (keyof typeof ExtensionPermission)[];
  config?: Record<ConfigVariableName, ConfigVariableSettings>;
}

export type ConfigVariableName = string;

export type ConfigVariableSettings = {
  type: keyof typeof ConfigType;
  displayName?: string;
  description?: string;
  required?: boolean;
  defaultValue?: any;
};

export type ConfigFields = Record<ConfigVariableName, ConfigVariableSettings>;

export type ConfigValue =
  | Record<string, boolean | number | string | undefined | null>
  | undefined;

export type Project = {
  id: string;
  name: string;
  mgmtApi: string;
  mgmtToken: string;
};

export type Environment = {
  id: string;
  name: string;
  endpoint: string;
  authToken: string;
};

export type Context = {
  project: Project;
  environment: Environment;
};

export interface ExtensionPropsBase {
  context: Context;
  openDialog: OpenDialog;
  showToast: ShowToast;
  openAssetPicker: OpenAssetPicker;
  redirectParent: (location: string | Location) => Promise<void>;
  historyReplace: (url: string | HistoryCallback) => Promise<void>;
  historyPush: (url: string | HistoryCallback) => Promise<void>;
}

export interface ExtensionDialogProps extends ExtensionPropsBase {
  onCloseDialog: (value: any) => void;
}

type HistoryCallback = (href: Location['href']) => string;
