export const UserKind = {
  MEMBER: 'MEMBER',
  PAT: 'PAT',
  PUBLIC: 'PUBLIC',
  WEBHOOK: 'WEBHOOK',
} as const;

export type User = {
  id: string;
  kind: keyof typeof UserKind;
  name: string;
  picture: string | null;
  isActive: boolean;
};
