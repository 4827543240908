import type { Form, Model, SetFieldsVisibility } from './type-helpers';
import type {
  IExtensionDeclaration,
  ConfigFields,
  ExtensionPropsBase,
  ConfigValue,
  ExtensionType,
} from './base';

import type { Field } from './type-helpers/field';

export const FieldExtensionType = {
  STRING: 'STRING',
  RICHTEXT: 'RICHTEXT',
  INT: 'INT',
  FLOAT: 'FLOAT',
  BOOLEAN: 'BOOLEAN',
  JSON: 'JSON',
  DATETIME: 'DATETIME',
  DATE: 'DATE',
  LOCATION: 'LOCATION',
  COLOR: 'COLOR',
  ENUMERATION: 'ENUMERATION',
  RELATION: 'RELATION',
  ASSET: 'ASSET',
  UNION: 'UNION',
} as const;

export const FieldExtensionFeature = {
  FieldRenderer: 'FieldRenderer',
  ListRenderer: 'ListRenderer',
  TableRenderer: 'TableRenderer',
} as const;

export interface FieldExtensionDeclaration extends IExtensionDeclaration {
  extensionType: typeof ExtensionType.field;
  fieldType: keyof typeof FieldExtensionType;
  /**
   * Extension capabilities
   */
  features: (keyof typeof FieldExtensionFeature)[];
  fieldConfig?: ConfigFields;
  listConfig?: ConfigFields;
  tableConfig?: ConfigFields;
}

export interface FieldExtensionProps extends ExtensionPropsBase {
  extension: {
    config: ConfigValue;
    tableConfig: ConfigValue;
    fieldConfig: ConfigValue;
    id: string;
  };

  isExpanded: boolean;
  expand: (expand: boolean | ((isExpanded: boolean) => boolean)) => unknown;

  // name of the field in the form (may differ from the field apiId, ie. for localized fields)
  name: string;

  // current locale on localized field
  locale: string;

  // current entry id, null for new entries;
  entryId: string | null;

  isTableCell: boolean;

  isReadOnly: boolean | undefined;

  value: any;

  onBlur: (event?: FocusEvent) => Promise<void>;

  onChange: (event: InputEvent | any) => Promise<void>;

  onFocus: (event?: FocusEvent) => Promise<void>;

  // @see https://final-form.org/docs/react-final-form/types/FieldRenderProps
  meta: {
    active: boolean;
    error: any;
    touched: boolean;
  };

  /**
   * @deprecated use `expand` instead
   */
  expandField: (expand: boolean) => unknown;

  form: Form;
  field: Field;
  model: Model;
  /**
   * @deprecated Please use {@link Form.setFieldsVisibility}
   */
  setFieldsVisibility: SetFieldsVisibility;
  /**
   * @deprecated Please use {@link ExtensionPropsBase.extension extension.fieldConfig}
   */
  fieldConfig: ConfigValue;
  /**
   * @deprecated Please use {@link ExtensionPropsBase.extension extension.tableConfig}
   */
  tableConfig: ConfigValue;
}
